$('.js-update-translit').on('click', function updateTranslitClick() {
  const titleField = $(`[name='${$(this).data('title-name')}'`).val();
  const id = $(this).data('id');
  const className = $(this).data('class-name');

  $.ajax({
    type: 'POST',
    url: `/admin/update_translit?id=${id}&class_name=${className}&title_value=${titleField}`,
    success: ({ translit }) => {
      if ($(this).closest('div').find('input').length) {
        $(this).closest('div').find('input').val(translit);
      } else {
        $(this).closest('div').find('span').text(translit);
      }
    },
  });
});
